export const routes = {
  home: process.env.PUBLIC_URL + '/',
  infantoM: process.env.PUBLIC_URL + '/infantil/masculino',
  infantoF: process.env.PUBLIC_URL + '/infantil/feminino',
  juvenilM: process.env.PUBLIC_URL + '/juvenil/masculino',
  juvenilF: process.env.PUBLIC_URL + '/juvenil/feminino',
  seniorM: process.env.PUBLIC_URL + '/senior/masculino',
  seniorF: process.env.PUBLIC_URL + '/senior/feminino',
  menuS: process.env.PUBLIC_URL + '/senior',
  menuI: process.env.PUBLIC_URL + '/infantil',
  pcd: process.env.PUBLIC_URL + '/pcd',
  pcdF: process.env.PUBLIC_URL + '/pcd/feminino',
  caiaque: process.env.PUBLIC_URL + '/caiaque',
  canoa: process.env.PUBLIC_URL + '/canoa',
  motorizada: process.env.PUBLIC_URL + '/motorizada'
}
