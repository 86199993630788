import LogoSvg from '../../assets/logo.svg'
import Risc from '../../assets/Branca.png'

const Header = ({ title }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '50px',
        marginBottom: '20px'
      }}
    >
      <img src={LogoSvg} alt="Logo" style={{ width: '10%', height: '100%' }} />
      <h1 style={{ fontSize: '30px', fontWeight: 'bold', color: '#fff' }}>
        {title}
      </h1>
      <img src={Risc} alt="Logo" style={{ width: '10%', height: '100%' }} />
    </div>
  )
}

export default Header
