import React from 'react'
import { useNavigate } from 'react-router-dom'

import { routes } from '../../routes/data'

const Home = () => {
  const navigate = useNavigate()
  return (
    <div>
      <h1>Título</h1>
      <ul>
        {Object.entries(routes).map(([label, url]) => (
          <li key={label}>
            <p
              onClick={() => navigate(url)}
              style={{
                cursor: 'pointer'
              }}
            >
              {label}
            </p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Home
