/* eslint-disable camelcase */
export const calcularRankings = entrada => {
  // Criar um objeto para armazenar os dados acumulados de cada time
  const times = {}

  // Iterar sobre cada entrada e calcular o número de registros e a pontuação total para cada time
  entrada.forEach(registro => {
    const { participant_number, team_name, fish_score } = registro

    if (times[participant_number]) {
      // Se o time já existe no objeto 'times', incrementar o número de registros e somar a pontuação total
      times[participant_number].count_fish++
      times[participant_number].fish_score =
        parseInt(times[participant_number].fish_score) + parseInt(fish_score)
      if (
        parseInt(fish_score) >
        parseInt(times[participant_number].highest_fish_score)
      ) {
        times[participant_number].highest_fish_score = fish_score
      }
    } else {
      // Se o time não existe no objeto 'times', criar um novo registro para o time
      times[participant_number] = {
        participant_number,
        team_name,
        count_fish: 1,
        fish_score,
        highest_fish_score: fish_score
      }
    }
  })

  // Converter o objeto 'times' em um array
  const timesArray = Object.values(times)

  // Ordenar o array com base na pontuação total em ordem decrescente e, em caso de empate, pelo maior fish_score individual
  timesArray.sort((a, b) => {
    if (parseInt(b.fish_score) !== parseInt(a.fish_score)) {
      return parseInt(b.fish_score) - parseInt(a.fish_score)
    } else {
      return parseInt(b.highest_fish_score) - parseInt(a.highest_fish_score)
    }
  })

  // Atribuir as posições de acordo com a ordem no array
  const saida = timesArray.map((time, index) => ({
    pos: index + 1,
    ...time
  }))

  return saida
}

// const entrada = [
//   { numTime: 123, nome: 'Team A', totalPontos: 100 },
//   { numTime: 456, nome: 'Team B', totalPontos: 80 },
//   { numTime: 789, nome: 'Team C', totalPontos: 40 },
//   { numTime: 321, nome: 'Team D', totalPontos: 40 },
//   { numTime: 321, nome: 'Team D', totalPontos: 60 },
//   { numTime: 789, nome: 'Team C', totalPontos: 40 },
//   { numTime: 321, nome: 'Team D', totalPontos: 40 },
//   { numTime: 456, nome: 'Team B', totalPontos: 80 },
//   { numTime: 321, nome: 'Team D', totalPontos: 40 }
// ]

// const resultado = calcularRankings(entrada)
// console.log(resultado)
