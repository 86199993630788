import CssBaseline from '@mui/material/CssBaseline'
import React from 'react'

import VisibilityIcon from '@mui/icons-material/Visibility'

import Risc from '../../assets/Branca.png'
import LogoSvg from '../../assets/logo.svg'

const fishList = ({ data, handleType, handleSelectedFish }) => {
  return (
    <div
      style={{
        width: '100vw',
        minHeight: '100vh',
        padding: '20px',
        background: 'linear-gradient(to right, #336698, #1C7C3E)'
      }}
    >
      <CssBaseline />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          marginBottom: '20px'
        }}
      >
        <div style={{ width: '20vw' }}>
          <img
            src={LogoSvg}
            alt="Logo"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
        <div>
          <h1 style={{ fontSize: '30px', fontWeight: 'bold', color: '#fff' }}>
            {data[0]?.fisher_name || 'Nome do Pescador'}
          </h1>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '10vw' }}
        >
          <img
            src={Risc}
            alt="Logo"
            style={{ width: '100%', height: '100%', marginBottom: '20px' }}
          />
          <button
            style={{
              padding: '10px 20px',
              backgroundColor: 'rgba(51, 51, 51, 0.7)',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              fontSize: '20px',
              cursor: 'pointer'
            }}
            onClick={() => handleType('ranking')}
          >
            Voltar
          </button>
        </div>
      </div>
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse'
        }}
      >
        <thead>
          <tr
            style={{
              background: 'rgba(51, 51, 51, 0.7)'
            }}
          >
            <th
              style={{
                width: '10%',
                padding: '10px',
                color: '#fff',
                textAlign: 'center'
              }}
            >
              ORDEM
            </th>
            <th
              style={{
                width: '50%',
                padding: '10px',
                color: '#fff',
                textAlign: 'center'
              }}
            >
              ESPÉCIE
            </th>
            <th
              style={{
                width: '10%',
                padding: '10px',
                color: '#fff',
                textAlign: 'center'
              }}
            >
              MEDIDA
            </th>
            <th
              style={{
                width: '20%',
                padding: '10px',
                color: '#fff',
                textAlign: 'center'
              }}
            >
              TOTAL DE PONTOS
            </th>
            <th
              style={{
                width: '10%',
                padding: '10px',
                color: '#fff',
                textAlign: 'center'
              }}
            >
              DETALHES
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((element, index) => (
            <tr
              key={index}
              style={{
                background:
                  index % 2 === 1
                    ? 'rgba(51, 51, 51, 0.4)'
                    : 'rgba(51, 51, 51, 0.2)',
                textAlign: 'center'
              }}
            >
              <td
                style={{
                  padding: '10px',
                  color: '#fff'
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  padding: '10px',
                  color: '#fff'
                }}
              >
                {element.modality === 'Senior' ||
                element.modality === 'InfantoJuvenil' ||
                element.modality === 'PCD'
                  ? '-'
                  : element.fish_name}
              </td>
              <td
                style={{
                  padding: '10px',
                  color: '#fff'
                }}
              >
                {element.fish_measure} cm
              </td>
              <td
                style={{
                  padding: '10px',
                  color: '#fff'
                }}
              >
                {element.fish_score}
              </td>
              <td
                style={{
                  padding: '10px',
                  color: '#fff'
                }}
              >
                <VisibilityIcon
                  onClick={() => {
                    handleSelectedFish(element)
                    handleType('details')
                  }}
                  style={{
                    cursor: 'pointer'
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default fishList
