import { useEffect, useState } from 'react'

import Details from '../../components/Details'
import FishList from '../../components/FishList'
import Ranking from '../../components/RankingPesca'
import axios from '../../services/api'
import api from '../../services/apiResquest'
import { calcularRankings } from '../../utils/functions'

import { token } from '../token'

const InfantoF = () => {
  const [fullData, setFullData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [active, setActive] = useState(false)
  const [tableType, setTableType] = useState('ranking')
  const [sNumber, setSNumber] = useState(null)
  const [fishList, setFishList] = useState([])
  const [sFish, setSFish] = useState(null)
  const [fiscalList, setFiscalList] = useState([])

  const handleActive = () => {
    setActive(!active)
  }

  const handleType = temptype => {
    setTableType(temptype)
  }

  const handleSelectedNumber = tempNumber => {
    setSNumber(tempNumber)
  }

  const handleSelectedFish = tempData => {
    setSFish(tempData)
  }

  const filterData = array => {
    let tempdata = array.filter(
      element =>
        (element.participant_number.trim().toUpperCase().startsWith('I1-') || 
        element.participant_number.trim().toUpperCase().startsWith('I2-')) &&
        element.sex === 'F'
    )

    tempdata = calcularRankings(tempdata)

    setFilteredData(tempdata.slice(0, active === false ? 5 : tempdata.length))
  }

  const loadFiscal = async () => {
    try {
      const resp = await api.get('/user', {
        headers: {
          Autentication: token
        },
        params: {
          all: true
        }
      })

      setFiscalList(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  const loadData = async () => {
    try {
      const response = await axios.get('/fishing', {
        params: {
          all: true,
          atributo: 'modality',
          value: 'InfantoJuvenil'
        }
      })
      setFullData(response.data)
      filterData(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    loadFiscal()
  }, [])

  useEffect(() => {
    if (sNumber) {
      const tempData = fullData.filter(e => e.participant_number === sNumber)
      setFishList(tempData)
    }
  }, [sNumber])

  useEffect(() => {
    if (fullData.length > 0) {
      filterData(fullData)
    }
  }, [active])

  useEffect(() => {
    loadData()
    setInterval(() => {
      loadData()
    }, [60000])
  }, [])

  return (
    <>
      {tableType === 'ranking' ? (
        <Ranking
          data={filteredData}
          active={active}
          setActive={handleActive}
          handleType={handleType}
          handleSelectedNumber={handleSelectedNumber}
          title="Ranking Preliminar Pesca Infantil Feminino"
        />
      ) : tableType === 'details' ? (
        <Details
          element={sFish}
          handleType={handleType}
          fiscalList={fiscalList}
        />
      ) : (
        <FishList
          data={fishList}
          handleType={handleType}
          handleSelectedFish={handleSelectedFish}
        />
      )}
    </>
  )
}

export default InfantoF
