import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  Modal,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import Logo from '../../assets/Contorno.png'

const Details = ({ element, handleType, fiscalList }) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [fiscal, setFiscal] = useState('')

  const filterFiscal = () => {
    const temp = fiscalList.find(
      e => e.uuid === element.responsible_registration_uuid
    )
    console.log(temp)

    setFiscal(temp.full_name)
  }

  const handleOpenModal = image => {
    setSelectedImage(image)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    if (openModal === true) {
      setOpenModal(false)
    }
  }

  const maskDate = dataOriginal => {
    const data = new Date(dataOriginal)

    const hora = data.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit'
    })
    const diaMesAno = data.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })

    const dataFormatada = `${hora} ${diaMesAno}`
    return dataFormatada
  }

  useEffect(() => {
    console.log(fiscalList)
    if (element !== null && fiscalList && fiscalList.length > 0) {
      filterFiscal()
    }
  }, [element, fiscalList])

  return (
    <Container
      maxWidth="lg"
      onClick={handleCloseModal}
      style={{
        background: 'linear-gradient(to right, #336698, #1C7C3E)'
      }}
    >
      <CssBaseline />
      <Box>
        <Typography variant="h4" color="white">
          Resgistrado por: {fiscal}
        </Typography>
        <Typography variant="h4" color="white">
          Código do Participante: {element.participant_number}
        </Typography>
        {/* <Typography>CPF: </Typography> */}
        <Typography variant="h4" color="white">
          Ficha: {element.ticket_number}
        </Typography>
        <Typography variant="h4" color="white">
          Hora do Registro: {maskDate(element.date)}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image={
                element.file_fisher_with_fish !== null
                  ? `${process.env.REACT_APP_API_URL_FISCAL}/file/${element.file_fisher_with_fish}`
                  : Logo
              }
              alt="Photo 1"
              onClick={() =>
                handleOpenModal(
                  element.file_fisher_with_fish !== null
                    ? `${process.env.REACT_APP_API_URL_FISCAL}/file/${element.file_fisher_with_fish}`
                    : Logo
                )
              }
              style={{ cursor: 'pointer' }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image={
                element.file_head_fish !== null
                  ? `${process.env.REACT_APP_API_URL_FISCAL}/file/${element.file_head_fish}`
                  : Logo
              }
              alt="Photo 2"
              onClick={() =>
                handleOpenModal(
                  element.file_head_fish !== null
                    ? `${process.env.REACT_APP_API_URL_FISCAL}/file/${element.file_head_fish}`
                    : Logo
                )
              }
              style={{ cursor: 'pointer' }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image={
                element.file_tail_fish !== null
                  ? `${process.env.REACT_APP_API_URL_FISCAL}/file/${element.file_tail_fish}`
                  : Logo
              }
              alt="Photo 3"
              onClick={() =>
                handleOpenModal(
                  element.file_tail_fish !== null
                    ? `${process.env.REACT_APP_API_URL_FISCAL}/file/${element.file_tail_fish}`
                    : Logo
                )
              }
              style={{ cursor: 'pointer' }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image={
                element.file_full_photo !== null
                  ? `${process.env.REACT_APP_API_URL_FISCAL}/file/${element.file_full_photo}`
                  : Logo
              }
              alt="Photo 4"
              onClick={() =>
                handleOpenModal(
                  element.file_full_photo !== null
                    ? `${process.env.REACT_APP_API_URL_FISCAL}/file/${element.file_full_photo}`
                    : Logo
                )
              }
              style={{ cursor: 'pointer' }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image={
                element.evaluation_sheet_photo !== null
                  ? `${process.env.REACT_APP_API_URL_FISCAL}/file/${element.evaluation_sheet_photo}`
                  : Logo
              }
              alt="Photo 5"
              onClick={() =>
                handleOpenModal(
                  element.evaluation_sheet_photo !== null
                    ? `${process.env.REACT_APP_API_URL_FISCAL}/file/${element.evaluation_sheet_photo}`
                    : Logo
                )
              }
              style={{ cursor: 'pointer' }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            size="large"
            variant="contained"
            onClick={() => handleType('list')}
          >
            Voltar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardMedia
              component="iframe"
              height="400"
              src={
                element.file_release_video !== null
                  ? `${process.env.REACT_APP_API_URL_FISCAL}/file/${element.file_release_video}`
                  : Logo
              }
              title="Video"
            />
          </Card>
        </Grid>
        <Modal open={openModal} onClose={handleCloseModal}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh'
            }}
          >
            <img
              src={selectedImage}
              alt="Zoomed Photo"
              style={{ maxHeight: '90%', maxWidth: '90%' }}
            />
          </div>
        </Modal>
      </Grid>
    </Container>
  )
}

export default Details
