import { Routes, Route, BrowserRouter } from 'react-router-dom'

import * as Pages from '../pages'
import { routes } from './data'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.home} element={<Pages.Home />} />
        <Route path={routes.caiaque} element={<Pages.Caiaque />} />
        <Route path={routes.canoa} element={<Pages.Canoa />} />
        <Route path={routes.infantoF} element={<Pages.InfantoF />} />
        <Route path={routes.infantoM} element={<Pages.InfantoM />} />
        <Route path={routes.juvenilF} element={<Pages.JuvenilF />} />
        <Route path={routes.juvenilM} element={<Pages.JuvenilM />} />
        <Route path={routes.seniorF} element={<Pages.SeniorF />} />
        <Route path={routes.seniorM} element={<Pages.SeniorM />} />
        <Route path={routes.menuS} element={<Pages.MenuSenior />} />
        <Route path={routes.menuI} element={<Pages.MenuInfantil />} />
        <Route path={routes.motorizada} element={<Pages.Motorizada />} />
        <Route path={routes.pcd} element={<Pages.PCD />} />
        <Route path={routes.pcdF} element={<Pages.PCDF />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
