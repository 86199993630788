import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'

import React from 'react'
import { useNavigate } from 'react-router-dom'

// import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { routes } from '../../routes/data'

const styles = {
  menu: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  menuButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  menuButton: {
    margin: '20px',
    padding: '15px 30px',
    width: '400px',
    backgroundColor: 'rgba(51, 51, 51, 0.7)',
    color: '#ffffff',
    borderRadius: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer'
  }
}

const Menu = () => {
  const navigate = useNavigate()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        background: 'linear-gradient(to right, #336698, #1C7C3E)'
      }}
    >
      <Header title="Ranking Preliminar Senior" />
      <div style={styles.menu}>
        <CssBaseline />
        <div style={styles.menuButtons}>
          <Button
            style={styles.menuButton}
            onClick={() => navigate(routes.seniorF)}
          >
            Senior Feminino
          </Button>
          <Button
            style={styles.menuButton}
            onClick={() => navigate(routes.seniorM)}
          >
            Senior Masculino
          </Button>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Menu
